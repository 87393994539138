<template>
    <v-app-bar 
    app 
    clipped-left 
    elevate-on-scroll
    color="translucient-white" 
    >
        <v-img
            :src="require('@/assets/logo-teal.png')"
            :max-height="isMobile ? '28' : '40'"
            :max-width="isMobile ? '150' : '200'"
            contain
            class="ml-md-15"
            @click="$router.push('/')"
            style="cursor: pointer;"
        ></v-img>
        <v-spacer></v-spacer>
        <v-btn text large class="text-none font-weight-black d-none d-md-block" @click="$router.push('/prices')">
            Precios
        </v-btn>
        <v-btn text large class="text-none font-weight-black d-none d-md-block" @click="$router.push('/help')">
            Ayuda
        </v-btn>
        <v-spacer></v-spacer>
        <v-btn color="teal" class="mr-3 pa-5 px-7 font-weight-black d-none d-md-flex" @click="$router.push('/login')" dark text>Iniciar Sesión</v-btn>
        <v-btn color="teal accent-4" class="mr-md-15 pa-5 px-7 font-weight-black d-none d-md-flex" @click="$router.push('/signup')" dark>Registrarse</v-btn>
        <v-btn color="teal" class="mr-0 d-md-none" icon small @click="$emit('toggleDrawer')">
            <v-icon>mdi-menu</v-icon>
        </v-btn>
    </v-app-bar>
</template>

<script>
// import router from '@/plugins/router';

export default {
  name: 'AppBar',
  data () {
    return {
    }
  },
  computed: {
    isMobile () {
      return window.innerWidth < 600
    }
  },
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
    a {
        text-decoration: none;
    }
</style>
