<template>
    <!-- TODO: Verificaar que email válido -->
    <v-dialog
        v-model="loginDialog"
        fullscreen
        transition="none"
    >
        <v-img
            class="d-flex justify-center"
            :src="require('@/assets/img/login-background.png')"
            style="position: fixed; top: 0; left: 0; width: 100vw; height: 100vh;"
        ></v-img>
        <v-card class="elevation-0" :class="!isMobile ? 'transparent' : 'white'">
            <v-toolbar flat color="transparent" style="position: sticky; top: 0;">
                <v-btn icon large @click="close">
                    <v-icon>mdi-chevron-left</v-icon>
                </v-btn>
            </v-toolbar>
            <v-card class="elevation-0 elevation-md-2 px-8 mb-md-15" :class="!$vuetify.breakpoint.mdAndDown && 'rounded-xl'" style="max-width: 500px; margin: auto;">
                <v-card-title class="mt-1 d-flex justify-center align-center">
                    <v-img max-height="40" contain class="ml-10 mt-5" :src="require('@/assets/logo-teal.png')"></v-img>
                </v-card-title>
                <v-card-text id="login-form">
                    <v-btn
                    block
                    class="mt-8 rounded-lg py-4"
                    color="primary"
                    small
                    @click="isSignUp=!isSignUp"
                    >
                    {{isSignUp ? 'INICIA SESIÓN' : 'REGÍSTRATE'}}
                    </v-btn>
                    <v-row class="my-7">
                        <v-col cols="5">
                            <v-divider></v-divider>
                        </v-col>
                        <v-col cols="2">
                            <span class="text-body-1 d-flex justify-center mt-n2">o</span>
                        </v-col>
                        <v-col cols="5">
                            <v-divider></v-divider>
                        </v-col>
                    </v-row>
                    <v-scroll-x-transition leave-absolute>
                        <v-form v-show="!isSignUp">
                            <v-text-field
                                prepend-icon="mdi-at"
                                v-model="dataLogin.email"
                                label="Email"
                                type="text"
                                outlined
                            ></v-text-field>
                            <v-text-field
                                prepend-icon="mdi-lock"
                                v-model="dataLogin.password"
                                label="Contraseña"
                                type="password"
                                outlined
                            ></v-text-field>
                            <v-checkbox
                                class="ml-8"
                                v-model="dataLogin.keepSession"
                                label="Mantener la sesión iniciada"
                            ></v-checkbox>
                        </v-form>
                    </v-scroll-x-transition>
                    <v-scroll-x-transition leave-absolute>
                        <v-form v-show="isSignUp">
                            <v-text-field
                                prepend-icon="mdi-domain"
                                v-model="dataSignUp.businessName"
                                label="Nombre de la empresa"
                                type="text"
                                outlined
                                :rules="[rules.businessNameRequired]"
                            ></v-text-field>
                            <v-text-field
                                prepend-icon="mdi-at"
                                v-model="dataSignUp.email"
                                label="Email"
                                type="text"
                                outlined
                                :rules="[rules.required, rules.email]"
                            ></v-text-field>
                            <v-text-field
                                prepend-icon="mdi-lock"
                                v-model="dataSignUp.password"
                                label="Contraseña"
                                :type="showPassword ? 'text' : 'password'"
                                outlined
                                :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
                                @click:append="showPassword = !showPassword"
                                loading
                                :rules="[rules.required, rules.min]"
                            >
                                <template v-slot:progress>
                                    <v-progress-linear
                                    :value="passwordReliability.value"
                                    :color="passwordReliability.color"
                                    class="mt-1"
                                    absolute
                                    height="3"
                                    ></v-progress-linear>
                                </template>
                            </v-text-field>
                            <v-text-field
                                prepend-icon="mdi-lock-check"
                                v-model="dataSignUp.repeatedPassword"
                                label="Repetir contraseña"
                                :type="showRepeatedPassword ? 'text' : 'password'"
                                outlined
                                :append-icon="showRepeatedPassword ? 'mdi-eye' : 'mdi-eye-off'"
                                @click:append="showRepeatedPassword = !showRepeatedPassword"
                                :rules="[rules.required, rules.samePassword(dataSignUp.password, dataSignUp.repeatedPassword)]"
                            ></v-text-field>
                            <v-text-field
                                prepend-icon="mdi-account"
                                v-model="dataSignUp.firstName"
                                label="Nombre"
                                type="text"
                                outlined
                                :rules="[rules.required]"
                            ></v-text-field>
                            <v-text-field
                                prepend-icon="mdi-card-account-details"
                                v-model="dataSignUp.lastName"
                                label="Apellidos"
                                type="text"
                                outlined
                                :rules="[rules.required]"
                            ></v-text-field>
                            <v-btn text plain color="primary" class="font-weight-semibold text-none" @click="isPromoCode = !isPromoCode" v-if="!isPromoCode">
                                <v-icon left>mdi-sale</v-icon>
                                Añadir código de promoción
                            </v-btn>
                            <v-text-field
                                v-if="isPromoCode && !promo.isCodeApplied"
                                prepend-icon="mdi-sale"
                                v-model="dataSignUp.promoCode"
                                label="Código de promoción"
                                type="text"
                                outlined
                                :error="promo.codeNotFount"
                                :hint="promo.codeNotFount && 'Código de promoción no válido'"
                                :rules="[promoRule]"
                            >
                                <template v-slot:append>
                                    <v-btn
                                    @click="handleClickApplyPromoCode"
                                    text
                                    plain
                                    class="mt-n2 text-none font-weight-bold"
                                    color="primary"
                                    >
                                        Aplicar
                                    </v-btn>
                                </template>
                            </v-text-field>
                            <div v-if="promo.isCodeApplied" class="d-flex justify-center">
                                <v-icon color="primary" left>mdi-check-decagram</v-icon>
                                <span class="primary--text">Código de promoción aplicado</span>
                            </div>
                        </v-form>
                    </v-scroll-x-transition>
                </v-card-text>
                <v-card-actions>
                    <v-btn color="primary" :disabled="allRequired" block @click="isSignUp ? signUp() : login()" class="rounded-lg py-6">{{isSignUp ? 'REGISTRARSE' : 'ENTRAR'}}</v-btn>
                </v-card-actions>
                <v-card-actions>
                    <v-card-subtitle v-show="!isSignUp" class="mb-0 pb-0">
                        <span>¿Olvidaste tu contraseña?</span>
                        <v-btn color="primary" plain small href="/reset"
                        >
                        RECUPERAR
                        </v-btn>
                    </v-card-subtitle>
                </v-card-actions>
                <v-card-actions class="py-10">
                    <v-img max-height="20" contain class="d-flex justify-center mt-n5" :src="require('@/assets/logo-white.png')"></v-img>
                </v-card-actions>
                <v-snackbar
                v-model="alertSnackbar"
                color="transparent"
                elevation="0"
                >
                    <v-alert
                    border="bottom"
                    colored-border
                    type="warning"
                    light
                    elevation="2"
                    >
                        {{alertMessage}}
                    </v-alert>
                </v-snackbar>
            </v-card>
        </v-card>
    </v-dialog>
</template>

<script>
export default {
    name: 'LoginView',
    props: ['isSignUpProp'],
    data () {
        return {
            urlServer: `${process.env.VUE_APP_API_HOST}:${process.env.VUE_APP_API_PORT}`,
            loginDialog: true,
            isSignUp: false,
            isPromoCode: false,
            promo: {
                isCodeApplied: false,
                codeNotFount: false,
            },
            alertSnackbar: false,
            alertMessage: '',
            showPassword: false,
            showRepeatedPassword: false,
            rules: {
            required: value => !!value || 'Este campo es necesario',
            businessNameRequired: value => !!value || 'Este campo es necesario',
            min: v => v.length >= 4 || 'Mínimo 4 caracteres',
            email: email => /.+@.+\..+/.test(email) || 'Email no válido',
            samePassword: (value, repeatedPassword) => value === repeatedPassword || 'Las contraseñas no coinciden',
            },
            dataLogin: {
                email: '',
                password: '',
                keepSession: false,
            },
            dataSignUp: {
                email: '',
                password: '',
                repeatedPassword: '',
                firstName: '',
                lastName: '',
                businessName: '',
                timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
                promoCode: null
            },
        }
    },
    computed: {
        isMobile () {
            return window.innerWidth < 600
        },
        passwordReliability () {
            if (this.dataSignUp.password.length === 0) {
                return {
                    value: 0,
                    color: ''
                }
            } else if (this.dataSignUp.password.length < 6) {
                return {
                    value: 25,
                    color: 'red'
                }
            } else if (this.dataSignUp.password.length < 8) {
                return {
                    value: 50,
                    color: 'orange'
                }
            } else {
                return {
                    value: 100,
                    color: 'green'
                }
            }
        },
        allRequired () {
            if (this.isSignUp) {
                return !(this.dataSignUp.email.length > 0 && this.dataSignUp.password.length > 0 && this.dataSignUp.repeatedPassword.length > 0 && this.dataSignUp.firstName.length > 0 && this.dataSignUp.lastName.length > 0)
            } else {
                return !(this.dataLogin.email.length > 0 && this.dataLogin.password.length > 0)
            }
        },
    },
    created () {
        if (this.isSignUpProp) {
            this.isSignUp = true
        }
    },
    methods: {
        close () {
            this.loginDialog = false
            this.$nextTick(() => {
                this.$router.back()
            })
        },
        log (msg) {
            this.alertMessage = msg
            this.alertSnackbar = true 
        },
        login () {
            this.log(this.urlServer)
            // If fields are not empty
            if (this.dataLogin.email.length > 0 && this.dataLogin.password.length > 0) {
                this.$store.dispatch('auth/login', this.dataLogin)
                .then(() => {
                    const businessId = localStorage.getItem('businessId')
                    this.$router.push(`businesses/${businessId}`)
                })
                .catch(error => console.error('Login error:', error));
            } else {
                this.alertMessage = 'No somos adivinos, debes rellenar todos los campos'
                this.alertSnackbar = true
            }
        },
        signUp () {
            // If fields are not empty
            if (this.dataSignUp.email.length > 0 && this.dataSignUp.password.length > 0 && this.dataSignUp.repeatedPassword.length > 0 && this.dataSignUp.firstName.length > 0 && this.dataSignUp.lastName.length > 0) {
                this.$store.dispatch('auth/signUp', this.dataSignUp)
                .then(() => {
                    const businessId = localStorage.getItem('businessId')
                    this.$router.push(`businesses/${businessId}`)
                })
                .catch(err => {
                    console.error('SignUp error:', err);
                    this.alertMessage = 'Se ha producido un error al dar de alta el usuario.'
                    // this.alertMessage = err
                    this.alertSnackbar = true
                });
            } else {
                this.alertMessage = 'No somos adivinos, debes rellenar todos los campos'
                this.alertSnackbar = true
            }
        },
        handleClickApplyPromoCode () {
            const promoCodes = ['JJBONNIN']
            if (promoCodes.includes(this.dataSignUp.promoCode)) {
                this.promo.isCodeApplied = true
                this.promo.codeNotFount = false
            } else {
                this.promo.isCodeApplied = false
                this.promo.codeNotFount = true
            }
        }
    }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>


</style>
