<template>
    <v-dialog v-model="loading" persistent max-width="290" fullscreen>
        <v-card style="background: #CCCCCC66;" elevation="0">
            <v-card-text class="d-flex flex-column align-center text-center">
                <v-progress-circular
                    class="loading"
                    indeterminate
                    color="primary"
                    size="64"
                ></v-progress-circular>
            </v-card-text>
        </v-card>
    </v-dialog>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
    name: 'LoadingDialog',
    computed: {
        ...mapGetters(['loading']),
    },
    methods: {

    },
}
</script>

<style scoped>
.loading {
    position: absolute;
    top: 48%;
    left: 50%;
    transform: translate(-50%, -50%);

}
</style>
