<template>
    <v-text-field v-model="localPhoneNumber" label="Teléfono" outlined dense class="mt-2">
        <template v-slot:prepend>
            <v-autocomplete v-model="localPhoneIso2" style="width: 130px !important;" outlined dense class="mt-n2"
                :items="countryPrefixes" item-value="iso2" :item-text="item => JSON.stringify(item)" single-line>
                <template v-slot:selection="{ item }">
                    <span class="fi" :class="`fi-${item.iso2.toLowerCase()}`"
                        style="min-width: 21.33px; min-height: 16px;"></span>
                    <span class="ml-2">+{{ item.phone_code }}</span>
                </template>
                <template v-slot:item="{ item }">
                    <span class="fi" :class="`fi-${item.iso2.toLowerCase()}`"></span>
                    <span class="ml-2 font-weight-regular">{{ item.name }}</span>
                </template>
            </v-autocomplete>
        </template>
    </v-text-field>
</template>

<script>
import "/node_modules/flag-icons/css/flag-icons.min.css";

export default {
    props: {
        value: {
            type: String,
            required: false,
        },
        phoneIso2: {
            type: String,
            required: false,
            default: 'ES'
        },
        phoneCode: {
            type: String,
            required: false
        }
    },
    data() {
        return {
            countryPrefixes: [],
            localPhoneNumber: '',
            localPhoneIso2: this.phoneIso2.toLowerCase(),
        };
    },
    computed: {
        localPhoneCode() {
            return this.countryPrefixes.find(item => item.iso2 === this.localPhoneIso2)?.phone_code;
        }
    },
    watch: {
        localPhoneNumber() {
            this.recalculate();
        },
        localPhoneIso2() {
            this.recalculate();
        },
        phoneIso2() {
            this.localPhoneIso2 = this.phoneIso2.toLowerCase();
        },
        value() {
            this.localPhoneNumber = this.value?.split(`+${this.localPhoneCode}`)[1]
            this.recalculate();
        }
    },
    methods: {
        recalculate() {
            if (this.value === undefined && !this.localPhoneNumber) return;
            const rawPhoneNumber = this.localPhoneNumber ? this.localPhoneNumber : this.value?.split(`+${this.localPhoneCode}`)[1];
            const localPhoneCode = this.countryPrefixes.find(item => item.iso2 === this.localPhoneIso2)?.phone_code;
            this.$emit('update:phoneIso2', this.localPhoneIso2);
            this.$emit('input', `+${localPhoneCode}${rawPhoneNumber}`);
            this.$emit('update:phoneCode', localPhoneCode);
        }
    },
    created() {
        const countryPrefixes = require('@/assets/country-prefixes.json')
        this.countryPrefixes = countryPrefixes.map(item => {
            return {
                ...item,
                phone_code: item.phone_code.toString().replace(' ', ''),
                iso2: item.iso2.toLowerCase()
            }
        });
        this.localPhoneNumber = this.value?.split(`+${this.localPhoneCode}`)[1]
        this.recalculate();
    }
};
</script>