<template>
    <v-navigation-drawer v-model="drawer" app right class="translucient-white pt-2" v-if="$vuetify.breakpoint.mdAndDown">
        <!-- <v-row class="mb-0">
            <v-col cols="8">
                <h3 class="ml-6 mt-2">Empresa</h3>
            </v-col>
            <v-col cols="4" class="d-flex justify-end">
                <v-btn fab text small class="mr-2" @click="drawer = !drawer">
                    <v-icon>mdi-chevron-right</v-icon>
                </v-btn>
            </v-col>
        </v-row>
        <v-divider></v-divider> -->
        <v-list dense nav class="py-2">
            <v-list-item-group
            v-model="selectedItem"
            color="secondary"
            class="pb-2"
            >
                <template>
                    <v-list-item
                    v-for="item in items"
                    :key="item.title"
                    link
                    @click="item.action()"
                    class="py-1"
                    >
                        <template>
                            <v-list-item-icon class="mx-2">
                                <v-icon left>{{ item.icon }}</v-icon>
                            </v-list-item-icon>
                            <v-list-item-content>
                                <v-list-item-title class="mt-1 font-weight-bold">{{ item.title }}</v-list-item-title>
                            </v-list-item-content>
                        </template>
                    </v-list-item>
                </template>
                <v-divider></v-divider>
                <v-btn small color="teal darken" class="my-3 py-5 font-weight-black" @click="$router.push('/login')" dark outlined block>Iniciar Sesión</v-btn>
                <v-btn small color="teal accent-4" class="my-3 py-5 font-weight-black" @click="$router.push('/signup')" dark block>REGISTRARSE</v-btn>
            </v-list-item-group>
        </v-list>
    </v-navigation-drawer>
</template>

<script>
// import { mapActions, mapGetters } from 'vuex'
// import HomeDialog from './HomeDialog.vue'
// import SiteDialog from './SiteDialog.vue'

export default {
    name: 'NavigationDrawer',
    props: ['toggleDrawer'],
    components: {
        // HomeDialog,
        // SiteDialog
    },
    data() {
        return {
            drawer: false,
            selectedItem: 0,
            items: [
            { 
                title: 'Inicio',
                icon: 'mdi-home',
                path: '/home',
                action: () => {
                    this.$router.push('/')
                }
            },
            // { 
            //     title: 'Login',
            //     icon: 'mdi-login',
            //     path: '/login',
            //     action: () => {
            //         this.$router.push('/home/dashboard')
            //     }
            // },
            { 
                title: 'Precios',
                icon: 'mdi-cash',
                path: '/prices',
                action: () => {
                    // console.log('Conócenos')
                    this.$router.push('/prices')
                }
            },
            // { 
            //     title: 'Únete al equipo',
            //     icon: 'mdi-account-multiple-plus',
            //     path: '/join-us',
            //     action: () => {
            //         this.$router.push('/join-us')
            //     }
            // },
            { 
                title: 'Ayuda',
                icon: 'mdi-phone',
                path: '/help',
                action: () => {
                    this.$router.push('/help')
                }
            },
        ],
        }
    },
    computed: {

    },
    watch: {
        toggleDrawer() {
            this.drawer = !this.drawer
        },
    },
    methods: {
        // ...
    },
    created() {
        // console.log(this.$route.path)
        this.selectedItem = this.items.findIndex(item => item.path === this.$route.path)
    },
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style>

</style>
