/* eslint-disable indent */
<template>
    <v-main>
        <v-container class="py-15 px-6" style="max-width: 1250px;">
            <v-row class="d-flex justify-center">
                <v-col cols="12" class="d-flex flex-column justify-end">
                    <h1 :style="$vuetify.breakpoint.mdAndUp ? 'font-size: 54px;' : 'font-size:40px;'" style="margin: auto;" class="text-center">Software de Programación de Citas
                        para una Amplia Variedad de Sectores</h1>
                    <!-- <h1 class="text-h2">Aplicación de Gestión de Reservas online para negocios</h1> -->
                    <h2 :style="$vuetify.breakpoint.mdAndUp ? 'font-size: 24px;' : 'font-size:17px;'" class="font-weight-regular py-8 text-center" style="margin: auto;">Potencia tus reservas,
                        expande tu clientela y observa cómo tu negocio se eleva a nuevos horizontes. <br />Desde
                        estudios de diseño hasta centros de spa,
                        Slotspot está aquí para respaldarte.</h2>
                    <!-- <v-btn class="pa-6 align-self-center" elevation="0" color="teal accent-4" dark><span class="custom-transform-class text-none font-weight-bold">Crear una cuenta</span></v-btn> -->
                    <v-row cols="12" class="d-flex justify-center">
                        <v-col cols="12" sm="6" md="4" class="pa-3 d-flex " v-for="sector in sectors"
                            :key="sector.title">
                            <v-hover v-slot="{ hover }">
                                <v-card class="pa-3 mx-3" :class="{ 'on-hover': hover }" style="width: 100%;"
                                    :href="`#${sector.id}`">
                                    <v-card-title class="pb-0">
                                        <v-icon large color="teal accent-4">{{ sector.icon }}</v-icon>
                                    </v-card-title>
                                    <v-card-title class="font-weight-black pb-3 teal--text text--accent-4">
                                        {{ sector.title }}
                                    </v-card-title>
                                </v-card>
                            </v-hover>
                        </v-col>
                    </v-row>
                    <!-- <h2 class="font-weight-regular py-8 text-center">Desde estudios de diseño hasta centros de spa,
                        Slotspot está aquí para respaldarte.</h2> -->
                </v-col>
            </v-row>
        </v-container>
        <v-container class="px-6" style="max-width: 1250px;" :style="$vuetify.breakpoint.mdAndUp ? 'padding-top: 6rem; padding-bottom: 6rem;' : 'padding-top: 4rem; padding-bottom: 4rem;'" v-for="sector in sectors" :key="sector.title"
            :id="sector.id">
            <v-row class="d-flex justify-center">
                <v-col cols="12" class="d-flex flex-column justify-center text-center">
                    <h1 :style="$vuetify.breakpoint.mdAndUp ? 'font-size: 45px;' : 'font-size:38px;'">{{ sector.title }}</h1>
                    <h3 class="font-weight-regular py-2">{{ sector.subtitle }}</h3>
                </v-col>
                <v-row cols="12" class="d-flex justify-center">
                    <v-col cols="12" sm="6" md="4" class="pa-3 d-flex align-stretch" v-for="feature in sector.features"
                        :key="feature.title">
                        <v-hover v-slot="{ hover }">
                            <v-card class="pa-3 mx-3" :class="{ 'on-hover': hover }">
                                <v-card-title class="pb-0">
                                    <v-icon large color="teal accent-4">{{ feature.icon }}</v-icon>
                                </v-card-title>
                                <v-card-title class="font-weight-black pb-3 teal--text text--accent-4 text-break">
                                    {{ feature.title }}
                                </v-card-title>
                                <v-card-text class="black--text font-weight-regular pb-6" style="font-size: 16px;">
                                    {{ feature.description }}
                                </v-card-text>
                            </v-card>
                        </v-hover>
                    </v-col>
                </v-row>
            </v-row>
        </v-container>
        <!-- <v-container class="py-15 mt-10 blue-grey lighten-5 px-3" fluid>
            <v-container class="mt-10" style="max-width: 1250px;">
                <v-row>
                    <v-col cols="12" class="mb-6 d-flex flex-column justify-center text-center">
                        <h1 style="font-size: 45px;">Productos adaptados a tu tipo de negocio</h1>
                        <h3 class="font-weight-regular py-2">Encuentra una solución específica pensada para tu tipo de
                            negocio concreto</h3>
                    </v-col>
                    <v-col cols="12">
                        <v-row class="d-flex justify-center">
                            <v-col cols="12" sm="6" md="4" class="d-flex align-stretch pa-3" v-for="product in products"
                                :key="product.title">
                                <v-hover v-slot="{ hover }">
                                    <v-card :class="{ 'on-hover-up': hover }">
                                        <v-img :src="product.img" height="200px"></v-img>

                                        <v-card-title class="py-6 px-8 text-h6 text-break font-weight-bold">
                                            {{ product.title }}
                                        </v-card-title>
                                    </v-card>
                                </v-hover>
                            </v-col>
                        </v-row>
                    </v-col>
                </v-row>
            </v-container>
        </v-container> -->
    </v-main>
</template>

<script>
// import { mapActions } from 'vuex'
// import axios from 'axios'

export default {
    name: 'HomeView',
    props: {
        sectorId: {
            type: String,
            required: false,
        }
    },
    data() {
        return {
            sectors: [
                {
                    id: 'bienestar',
                    title: 'Bienestar y belleza',
                    subtitle: '"La belleza comienza en el instante en que decides ser tú misma." - Coco Chanel',
                    icon: 'mdi-spa',
                    features: [
                        {
                            title: "Estudio de Belleza",
                            icon: "mdi-face-woman-shimmer-outline",
                            description: "Maximiza las reservas para tratamientos faciales y estéticos con el botón de reserva fácil y accesible de Slotspot, integrable en tu página web y redes sociales."
                        },
                        {
                            title: "Estilismo Capilar",
                            icon: "mdi-hair-dryer-outline",
                            description: "Mantén tu salón burbujeante de actividad y tu equipo organizado con recordatorios de citas proactivos y eficientes de Slotspot."
                        },
                        {
                            title: "Peluquería y barbería",
                            icon: "mdi-scissors-cutting",
                            description: "Ahorra minutos valiosos y enfoca tu habilidad en el corte perfecto, mientras Slotspot maneja de manera inteligente tus tareas administrativas cotidianas."
                        },
                        {
                            title: "Centro de Uñas",
                            icon: "mdi-hand-back-right",
                            description: "Mantén un flujo constante de clientes y sus preferencias a tu alcance, utilizando la intuitiva aplicación de Slotspot."
                        },
                        {
                            title: "Spa",
                            icon: "mdi-flower-tulip-outline",
                            description: "Organiza y coordina los horarios de tu staff con visualizaciones de colores e inteligente sincronización de agendas, todo bajo el paraguas de Slotspot."
                        },
                        {
                            title: "Terapias de Masaje",
                            icon: "mdi-meditation",
                            description: "Minimiza los dolores de cabeza de la planificación con las 24/7 reservas en línea y las actualizaciones automáticas que Slotspot habilita para tu negocio."
                        },
                        {
                            title: "Belleza para Novias",
                            icon: "mdi-ring",
                            description: "Haz que más futuras novias digan \"Sí\" a tus servicios con la función de reservas instantáneas y confirmaciones inmediatas de Slotspot."
                        },
                        {
                            title: "Estudio de Tatuajes",
                            icon: "mdi-translate",
                            description: "Puedes concentrarte en tu arte y en crear impresionantes tatuajes mientras Slotspot facilita las reservas directas desde todas las plataformas de redes sociales."
                        }
                    ]
                },
                {
                    id: 'sanitarios',
                    title: 'Sanitarios privados',
                    subtitle: '"La salud es una joya que los sanos guardan en un cofre y que sólo los enfermos pueden abrir." - Proverbio',
                    icon: 'mdi-hospital-box',
                    features: [
                        {
                            title: 'Centro Clínico',
                            icon: 'mdi-hospital-building',
                            description: 'Fomenta la autonomía de los pacientes permitiéndoles gestionar sus propias reservas y citas a través de Slotspot.'
                        },
                        {
                            title: 'Consulta de Asesoramiento',
                            icon: 'mdi-account-group',
                            description: 'Facilita la continuidad en la atención al paciente con citas regulares y mensajes recordatorios, utilizando Slotspot.'
                        },
                        {
                            title: 'Fisioterapia',
                            icon: 'mdi-yoga',
                            description: 'Harmoniza fácilmente los horarios y disponibilidades de todos los terapeutas mediante una administración de turnos intuitiva proporcionada por Slotspot.'
                        },
                        {
                            title: 'Vacunación e Inmunización',
                            icon: 'mdi-needle',
                            description: 'Optimiza la administración de vacunas con la reserva en línea 24/7 y recordatorios de citas, gracias a Slotspot.'
                        },
                        {
                            title: 'Odontología y Ortodoncia',
                            icon: 'mdi-tooth',
                            description: 'Haz que las sonrisas saludables sean una prioridad manteniendo las preferencias e historial de reservas de los pacientes siempre a mano con Slotspot.'
                        },
                        {
                            title: 'Pruebas de COVID-19',
                            icon: 'mdi-virus',
                            description: 'Administra y coordina los centros de pruebas en diversas ubicaciones de manera eficaz y rápida con Slotspot.'
                        },
                        {
                            title: 'Hospitales',
                            icon: 'mdi-hospital',
                            description: 'Facilita la gestión del personal, pacientes y clínicas sin inconvenientes utilizando las herramientas de Slotspot.'
                        }

                    ]
                },
                {
                    id: 'educacion',
                    title: 'Educación y formación',
                    subtitle: '"La educación es el arma más poderosa que puedes usar para cambiar el mundo." - Nelson Mandela',
                    icon: 'mdi-school',
                    features: [
                        {
                            title: 'Entorno Escolar y Docencia',
                            icon: 'mdi-school',
                            description: 'Simplifica la organización de clases y encuentros mediante la administración del calendario en línea de Slotspot.'
                        },
                        {
                            title: 'Cursos Online',
                            icon: 'mdi-laptop',
                            description: 'Desarrolla una comunidad estudiantil global y colma las brechas de tu agenda con las robustas herramientas de marketing que te ofrece Slotspot.'
                        },
                        {
                            title: 'Mentoría y Tutoría',
                            icon: 'mdi-account-group',
                            description: 'Monitorea el avance de tus estudiantes mediante una gestión de usuarios y ajustes de preferencias efectivos con Slotspot.'
                        },
                        {
                            title: 'Voluntariado',
                            icon: 'mdi-hand-heart',
                            description: 'Potencia tu capacidad de ayudar a los demás gestionando y programando equipos numerosos de manera eficaz con Slotspot.'
                        },
                        {
                            title: 'Encuentros entre Padres y Docentes',
                            icon: 'mdi-account-multiple',
                            description: 'Mantén a los padres al tanto con un calendario accesible de reuniones entre docentes y padres, todo a un clic de distancia con Slotspot.'
                        },
                        {
                            title: 'Clases de Arte',
                            icon: 'mdi-palette',
                            description: 'Enriquece tus clases integrando el botón "Reservar Ahora" en tu sitio web y redes sociales, gracias a Slotspot.'
                        },
                        {
                            title: 'Escuela de Conducción',
                            icon: 'mdi-car',
                            description: 'Reduce las inasistencias mediante recordatorios automáticos de citas proporcionados por Slotspot.'
                        },
                        {
                            title: 'Lecciones de Música',
                            icon: 'mdi-music',
                            description: 'Mantén el ritmo y sigue de cerca el avance de tus estudiantes con la gestión de clientes que Slotspot te ofrece.'
                        },
                        {
                            title: 'Horarios de Consulta',
                            icon: 'mdi-calendar-clock',
                            description: 'Facilita la programación de consultas estudiantiles con la ayuda intuitiva de Slotspot.'
                        }
                    ]
                },
                {
                    id: 'deportes',
                    title: 'Deportes y ocio',
                    subtitle: '"El cuerpo logra lo que la mente cree." - Anónimo',
                    icon: 'mdi-dumbbell',
                    features: [
                        {
                            title: 'Gimnasio',
                            icon: 'mdi-dumbbell',
                            description: 'Aumenta el número de clientes y reservas con una potente página de reservas de Slotspot.'
                        },
                        {
                            title: 'Estudio de fitness',
                            icon: 'mdi-run-fast',
                            description: 'Alcanza tus metas con la gestión y reserva de eventos grupales simplificada por Slotspot.'
                        },
                        {
                            title: 'Yoga',
                            icon: 'mdi-yoga',
                            description: 'Fluye con el prana manteniendo un programa de fidelización de clientes con Slotspot.'
                        },
                        {
                            title: 'Pilates',
                            icon: 'mdi-account-heart',
                            description: 'Impulsa tus clases añadiendo más energía con la aplicación de programación de citas en línea de Slotspot.'
                        },
                        {
                            title: 'Entrenamiento personal',
                            icon: 'mdi-weight-lifter',
                            description: 'Rastrea el progreso de tus clientes y diseña entrenamientos personalizados con Slotspot.'
                        },
                        {
                            title: 'Estudio de danza',
                            icon: 'mdi-dance-ballroom',
                            description: 'Mantente en movimiento con las reservas que llegan directamente desde las redes sociales gracias a Slotspot.'
                        },
                        {
                            title: 'Artes marciales',
                            icon: 'mdi-karate',
                            description: 'Potencia tus clases de Yudo utilizando las herramientas de marketing de Slotspot.'
                        }
                    ]
                },
                {
                    id: 'eventos',
                    title: 'Eventos y espectáculos',
                    subtitle: '"La vida es un gran lienzo, y debes lanzar sobre él toda la pintura que puedas." - Danny Kaye',
                    icon: 'mdi-movie',
                    features: [
                        {
                            title: 'Lanzamientos de producto',
                            icon: 'mdi-package-variant-closed',
                            description: 'Impresiona en tus presentaciones con la capacidad de Slotspot para gestionar reservas de invitados y confirmaciones en tiempo real.'
                        },
                        {
                            title: 'Conciertos y festivales',
                            icon: 'mdi-music-note',
                            description: 'Gestiona la asistencia y las ventas de entradas de manera eficiente con las herramientas de venta y reserva de Slotspot.'
                        },
                        {
                            title: 'Representaciones teatrales',
                            icon: 'mdi-theater',
                            description: 'Mantén un registro detallado de tus asientos vendidos y disponibles, y ofrece una experiencia de reserva sin inconvenientes con Slotspot.'
                        },
                        {
                            title: 'Galerías y exposiciones',
                            icon: 'mdi-image-multiple',
                            description: 'Asegura una afluencia constante y organiza visitas guiadas con el sistema de reserva y confirmación instantánea de Slotspot.'
                        },
                        {
                            title: 'Estrenos de películas',
                            icon: 'mdi-movie-roll',
                            description: 'Maximiza la asistencia y gestiona las entradas de tus estrenos con las herramientas de Slotspot, garantizando una experiencia cinematográfica de primera clase.'
                        },
                        {
                            title: 'Seminarios y talleres',
                            icon: 'mdi-school',
                            description: 'Facilita la inscripción y el seguimiento de los participantes, y asegura que tus eventos estén siempre al máximo de su capacidad con las soluciones de gestión de Slotspot.'
                        }
                    ]
                },
                {
                    id: 'otros',
                    title: 'Otros negocios',
                    subtitle: '"El secreto para triunfar en los negocios es saber algo que nadie más sabe." - Aristóteles Onassis',
                    icon: 'mdi-briefcase',
                    features: [
                        {
                            title: 'Servicios financieros',
                            icon: 'mdi-currency-usd',
                            description: 'Optimiza tu calendario con reservas estratégicas y recordatorios puntuales gracias a Slotspot.'
                        },
                        {
                            title: 'Planificación de entrevistas',
                            icon: 'mdi-account-tie',
                            description: 'Atrae a más candidatos utilizando herramientas de reserva instantánea y ajuste de disponibilidad online con Slotspot.'
                        },
                        {
                            title: 'Servicios legales',
                            icon: 'mdi-briefcase',
                            description: 'Eleva la eficiencia organizando tus reuniones con clientes mediante codificación de colores y sincronización de calendarios del equipo en Slotspot.'
                        },
                        {
                            title: 'Consultas',
                            icon: 'mdi-finance',
                            description: 'Expande tu base de clientes con herramientas de marketing digital adaptadas para web y redes sociales de Slotspot.'
                        },
                        {
                            title: 'Coaching',
                            icon: 'mdi-account-voice',
                            description: 'Ofrece coaching personalizado con confirmaciones automáticas y notificaciones a los clientes a través de Slotspot.'
                        },
                        {
                            title: 'Fotografía',
                            icon: 'mdi-camera',
                            description: 'Maximiza las capacidades de tu negocio con el Asistente Digital de Slotspot.'
                        },
                        {
                            title: 'Conferencia',
                            icon: 'mdi-human-capacity-increase',
                            description: 'Amplifica tu voz con la organización de grandes eventos y gestión de reservas usando Slotspot.'
                        }
                    ]
                }
            ],
        }
    },
    computed: {

    },
    mounted() {
        if (this.sectorId) document.location = '#' + this.sectorId
    },
    methods: {

    }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
/* .capitalize {
  text-transform: lowercase !important;
}

.capitalize::first-letter {
  text-transform: uppercase !important;
} */

.v-card {
    transition: box-shadow .3s ease-in-out, margin-top .2s ease-in-out;
    box-shadow: 4px 4px 16px 4px rgba(114, 114, 114, 0.2) !important;
    cursor: pointer;
    border: 1px solid rgba(0, 0, 0, 0) !important;
    border-radius: 10px;
}

.v-card:not(.on-hover) {
    box-shadow: 0 0 0 0 rgba(0, 0, 0, 0) !important;
    border: 1px solid rgba(0, 0, 0, 0.12) !important;
}

.v-card:not(.on-hover-up) {
    margin-top: 5px !important;
    border-radius: 10px !important;
}

.on-hover-up {
    border-radius: 10px !important;
}
</style>
