<template>
    <!-- TODO: Añadir "Popular" y "Ahorras un 17%" -->
    <v-main>
        <v-container class="py-15 pa-lg-15 d-flex justify-center flex-column" fluid>
            <h1 style="font-size: 52px;" class="text-center d-none d-sm-block">Precios transparentes que puedes entender</h1>
            <h1 style="font-size: 45px;" class="text-center d-sm-none">Precios transparentes que puedes entender</h1>
            <div class="py-8 d-flex justify-center">
                <v-btn large class="elevation-0 rounded-lg rounded-r-0"  :color="planType=='monthly' ? 'teal accent-4' : ''" :dark="planType=='monthly'" @click="planType='monthly'">Mensual</v-btn>
                <v-btn large class="elevation-0 rounded-lg rounded-l-0" @click="planType='yearly'" :color="planType=='yearly' ? 'teal accent-4' : ''" :dark="planType=='yearly'">Anual</v-btn>
            </div>
            <v-row>
                <v-col cols="12" md="6" lg="3" class="px-3" v-for="(plan, idx) in plans" :key="plan.title">
                    <v-card outlined height="100%" class="rounded-lg d-flex flex-column">
                        <v-card-title class="pa-10 d-flex justify-center flex-column text-break text-center">
                            <h2 style="font-size: 25px;" class="teal--text text--accent-4">{{ plan.title }}</h2>
                            <p style="font-size: 14px;" class="pt-3">{{ plan.description }}</p>
                            <h1 style="font-size: 48px;" class="mt-6">{{ plan.price[planType] }}<span style="font-size: 28px;"> {{ plan.currency }}{{ idx > 0 ? `/${plan.period[planType]}` : '' }}</span></h1>
                            <v-chip color="teal accent-4" class="ml-auto mt-2" dark small label :style="plan.price[planType]>0 ? '' : 'opacity: 0;'">+1 mes gratis</v-chip>
                        </v-card-title>
                        <v-divider></v-divider>
                        <v-card-text class="pa-6">
                            <p v-for="(item, idx) in plan.features" :key="`${plan.title}-${idx}`" style="font-size: 15px;" class="font-weight-bold"><v-icon left color="teal accent-4" class="mr-3">mdi-check</v-icon>{{ item.text }}</p>
                        </v-card-text>
                        <v-card-actions class="pa-10 d-flex justify-center pt-0 mt-auto">
                            <v-btn large color="primary" class="px-6 py-5 rounded-lg">Contratar</v-btn>
                        </v-card-actions>
                    </v-card>
                </v-col>
            </v-row>
        </v-container>
    </v-main>
</template>

<script>

export default {
    name: 'PricesView',
    data () {
        return {
            planType: 'yearly',
            plans: [
                {
                    title: 'Plan Gratuito',
                    description: 'Prueba nuestro plan gratuito y descubre nuestras funcionalidades sin compromiso',
                    price: {
                        monthly: 'Gratis',
                        yearly: 'Gratis'
                    },
                    period: {
                        monthly: 'mes',
                        yearly: 'mes'
                    },
                    currency: '',
                    features: [
                        { icon: 'mdi-check', text: '20 reservas al mes' },
                        { icon: 'mdi-check', text: '50 clientes' },
                        // { icon: 'mdi-check', text: '1 tipo de servicio' },
                        // { icon: 'mdi-check', text: 'Soporte vía email' },
                        // { icon: 'mdi-check', text: 'Perfiles de clientes' },
                        // { icon: 'mdi-check', text: 'Acceso a la app móvil' },
                        // { icon: 'mdi-check', text: 'Notificaciones por correo electrónico' },
                        { icon: 'mdi-check', text: 'Configuración de horas laborables' },
                        { icon: 'mdi-check', text: 'Modificación de reservas' },
                        // { icon: 'mdi-check', text: 'Integración con Google Calendar' },
                        // { icon: 'mdi-check', text: 'Notas de clientes' },
                        // { icon: 'mdi-check', text: 'Configuración de recordatorios' },
                        // { icon: 'mdi-check', text: 'Programación en bloque' },
                        // { icon: 'mdi-check', text: 'Seguimiento de citas' },
                        // { icon: 'mdi-check', text: 'Verificación por correo electrónico' },
                        // { icon: 'mdi-check', text: 'Bloqueo de clientes' }
                    ]
                },
                {
                    title: 'Plan Básico',
                    description: 'Ideal para pequenas empresas y autónomos están iniciando su camino hacia el éxito',
                    price: {
                        monthly: '9,99',
                        yearly: '6'
                    },
                    period: {
                        monthly: 'mes',
                        yearly: 'mes'
                    },
                    currency: '€',
                    features: [
                        { icon: 'mdi-check', text: '100 reservas al mes' },
                        { icon: 'mdi-check', text: '200 clientes' },
                        { icon: 'mdi-check', text: '2 tipos de servicio' },
                        // { icon: 'mdi-check', text: 'Soporte vía email' },
                        // { icon: 'mdi-check', text: 'Perfiles de clientes' },
                        // { icon: 'mdi-check', text: 'Acceso a la app móvil' },
                        // { icon: 'mdi-check', text: 'Notificaciones por correo electrónico' },
                        { icon: 'mdi-check', text: 'Configuración de horas laborables' },
                        { icon: 'mdi-check', text: 'Modificación de reservas' },
                        // { icon: 'mdi-check', text: 'Integración con Google Calendar' },
                        // { icon: 'mdi-check', text: 'Notas de clientes' },
                        // { icon: 'mdi-check', text: 'Configuración de recordatorios' },
                        // { icon: 'mdi-check', text: 'Programación en bloque' },
                        // { icon: 'mdi-check', text: 'Seguimiento de citas' },
                        { icon: 'mdi-check', text: 'Verificación por correo electrónico' },
                        // { icon: 'mdi-check', text: 'Bloqueo de clientes' }
                    ]
                },
                {
                    title: 'Plan Estándar',
                    description: 'Ideal para empresas consolidadas con cifras de facturación y clientes estables',
                    price: {
                        monthly: '12,99',
                        yearly: '9'
                    },
                    period: {
                        monthly: 'mes',
                        yearly: 'mes'
                    },
                    currency: '€',
                    features: [
                        { icon: 'mdi-check', text: '500 reservas al mes' },
                        { icon: 'mdi-check', text: 'Clientes ilimitados' },
                        { icon: 'mdi-check', text: 'Hasta 5 tipos de servicios' },
                        // { icon: 'mdi-check', text: 'Soporte vía chat en vivo' },
                        { icon: 'mdi-check', text: 'Perfiles de clientes con historial de reservas' },
                        // { icon: 'mdi-check', text: 'Informes de rendimiento mensual' },
                        // { icon: 'mdi-check', text: 'Integración con Facebook' },
                        { icon: 'mdi-check', text: 'Etiquetas de clientes' },
                        { icon: 'mdi-check', text: 'Configuración de vacaciones' },
                        { icon: 'mdi-check', text: 'Notificaciones por correo electrónico' },
                        { icon: 'mdi-check', text: 'Generación código QR para enlazar tu negocio' },
                        // { icon: 'mdi-check', text: 'Recordatorios por SMS' },
                        // { icon: 'mdi-check', text: 'Historial de cambios en las citas' },
                        // { icon: 'mdi-check', text: 'API de desarrolladores' },
                        { icon: 'mdi-check', text: 'Notas internas' },
                        // { icon: 'mdi-check', text: 'Seguimiento de cancelaciones' },
                        // { icon: 'mdi-check', text: 'Reservas recurrentes' },
                        // { icon: 'mdi-check', text: 'Asistencia prioritaria' },
                        // { icon: 'mdi-check', text: 'Configuración de políticas de cancelación' },
                        // { icon: 'mdi-check', text: 'Exportación de datos' },
                        // { icon: 'mdi-check', text: 'Verificación por SMS' },
                        // { icon: 'mdi-check', text: 'Tarjetas de cliente' },
                        // { icon: 'mdi-check', text: 'Reservas de grupo' },
                        { icon: 'mdi-check', text: 'Bloqueo de clientes' },
                        { icon: 'mdi-check', text: 'Seguimiento de citas' }
                    ]
                },
                {
                    title: 'Plan Premium',
                    description: 'Con funcionalidades avanzadas para analizar el rendimiento del negocio y optimizar la gestión',
                    price: {
                        monthly: '27,99',
                        yearly: '23'
                    },
                    period: {
                        monthly: 'mes',
                        yearly: 'mes'
                    },
                    currency: '€',
                    features: [
                        { icon: 'mdi-check', text: 'Reservas ilimitadas' },
                        { icon: 'mdi-check', text: 'Clientes ilimitados' },
                        { icon: 'mdi-check', text: 'Tipos de servicios ilimitados' },
                        // { icon: 'mdi-check', text: 'Soporte vía teléfono y chat en vivo' },
                        { icon: 'mdi-check', text: 'Perfiles de clientes avanzados' },
                        { icon: 'mdi-check', text: 'Informes de rendimiento mensual' },
                        // { icon: 'mdi-check', text: 'Informes de rendimiento personalizados' },
                        // { icon: 'mdi-check', text: 'Funcionalidad de marketing por email' },
                        // { icon: 'mdi-check', text: 'Función de tarjetas regalo y promociones' },
                        // { icon: 'mdi-check', text: 'Configuración de múltiples ubicaciones' },
                        // { icon: 'mdi-check', text: 'Administración de equipos' },
                        // { icon: 'mdi-check', text: 'Facturación personalizada' },
                        // { icon: 'mdi-check', text: 'Pago en línea' },
                        // { icon: 'mdi-check', text: 'Integración con Google Ads' },
                        // { icon: 'mdi-check', text: 'Notificaciones sin de publicidad de Slotspot' },
                        { icon: 'mdi-check', text: 'Configuración de políticas de cancelación' },
                        { icon: 'mdi-check', text: 'Estadísticas avanzadas' },
                        // { icon: 'mdi-check', text: 'Creación de cupones de descuento' },
                        // { icon: 'mdi-check', text: 'Reservas en espera' },
                        // { icon: 'mdi-check', text: 'Reservas en lista de espera' },
                        { icon: 'mdi-check', text: 'Personalización de perfil de empresa' },
                        { icon: 'mdi-check', text: 'Seguimiento de cancelaciones' },
                        { icon: 'mdi-check', text: 'Verificación por SMS' },
                        { icon: 'mdi-check', text: 'Recordatorios por SMS' },
                        { icon: 'mdi-check', text: 'Exportación de datos' },
                        { icon: 'mdi-check', text: 'Subdominio de empresa personalizable' },
                        // { icon: 'mdi-check', text: 'Integración con sistemas de gestión' },
                        // { icon: 'mdi-check', text: 'Soporte 24/7' },
                        // { icon: 'mdi-check', text: 'Integración con sistemas de punto de venta' },
                        // { icon: 'mdi-check', text: 'Módulo de facturación' },
                        // { icon: 'mdi-check', text: 'Reservas de evento' },
                        // { icon: 'mdi-check', text: 'Función de membresía' },
                        // { icon: 'mdi-check', text: 'Funcionalidad de subcuentas' },
                        // { icon: 'mdi-check', text: 'Servicio de migración de datos' }
                    ]
                }
            ]

        }
    },
    computed: {

    },
    mounted () {

    },
    methods: {
        
    }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>


</style>
