<template>
    <v-dialog v-model="scheduleDialog" max-width="900" :fullscreen="$vuetify.breakpoint.mdAndDown">
        <v-card>
            <v-card-title class="font-weight-bold py-6" style="font-size: 25px;">Horario laboral</v-card-title>
            <v-card-text>
                <v-card v-for="(day, dayIdx) in updatedSchedule" outlined class="pa-4 pa-lg-5 rounded-0"
                    :class="dayIdx == 0 ? 'rounded-t-lg' : dayIdx == updatedSchedule.length - 1 ? 'rounded-b-lg' : ''"
                    :key="`wd-dialog-${day.day}`" :style="dayIdx != 0 ? 'border-top: none;' : ''">
                    <v-row>
                        <v-col cols="12" lg="3" class="d-flex flex-row align-center">
                            <v-checkbox class="mt-0 text-capitalize" v-model="day.isOpen" :label="getWeekDay(day.day)"
                                color="teal accent-4" hide-details off-icon="mdi-clock-remove-outline"
                                on-icon="mdi-clock-check"></v-checkbox>
                        </v-col>
                        <v-col cols="12" lg="6">
                            <div v-if="day.isOpen">
                                <div class="d-flex flex-row align-center mb-2" v-for="(row, rowIdx) in day.opening" :key="`row-${day.day}-${rowIdx}`">
                                    <span class="mx-2">De</span>
                                    <v-menu ref="menu" v-model="menu[dayIdx][rowIdx]" :close-on-content-click="false" :nudge-right="40"
                                        transition="scale-transition" offset-y max-width="290px"
                                        min-width="290px">
                                        <template v-slot:activator="{ on, attrs }">
                                            <v-text-field class="pa-0 schedule-input" v-model="row.open" prepend-inner-icon="mdi-clock-time-four-outline" v-bind="attrs"
                                                v-on="on" hide-details outlined dense></v-text-field>
                                        </template>
                                        <v-time-picker v-if="menu[dayIdx][rowIdx]" v-model="row.open" format="24hr" min="00:00" max="23:59" color="primary"></v-time-picker>
                                    </v-menu>
                                    <span class="mx-2">a</span>
                                    <v-menu ref="menu" v-model="menu2[dayIdx][rowIdx]" :close-on-content-click="false" :nudge-right="40"
                                        transition="scale-transition" offset-y max-width="290px"
                                        min-width="290px">
                                        <template v-slot:activator="{ on, attrs }">
                                            <v-text-field class="py-0 schedule-input" v-model="row.close" prepend-inner-icon="mdi-clock-time-four-outline" v-bind="attrs"
                                                v-on="on" hide-details outlined dense></v-text-field>
                                        </template>
                                        <v-time-picker v-if="menu2[dayIdx][rowIdx]" v-model="row.close" format="24hr" min="00:00" max="23:59" color="primary"></v-time-picker>
                                    </v-menu>
                                    <v-btn x-small icon class="ml-2" v-if="rowIdx>0" @click="removeTimeSlot(day, rowIdx)"><v-icon>mdi-close-box</v-icon></v-btn>
                                </div>
                            </div>
                        </v-col>
                        <v-col cols="12" lg="3" class="text-right d-flex flex-row align-center">
                            <v-btn v-if="day.isOpen" outlined small color="primary" class="font-weight-bold body-2 py-4" @click="addTimeSlot(day)"><v-icon dense left>mdi-plus</v-icon>Añadir tramo</v-btn>
                        </v-col>
                    </v-row>
                </v-card>
            </v-card-text>
            <v-card-actions class="py-lg-6 pb-6">
                <v-spacer></v-spacer>
                <v-btn color="grey lighten-1" class="mr-3 pa-5 px-6 font-weight-black elevation-0"
                    @click="close()" outlined><span
                        class="grey--text text--darken-1">Cancelar</span></v-btn>
                <v-btn color="teal accent-4" class="mr-3 pa-5 px-6 font-weight-black elevation-0"
                    @click="save()" dark>Guardar</v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
// import { mapGetters } from 'vuex'
import moment from 'moment'

export default {
    name: 'ScheduleDialog',
    props: {
        value: {
            Boolean,
            default: false
        },
        schedule: {
            Array,
            default: () => {
                return [
                    {
                        day: 1,
                        isOpen: false,
                        opening: [
                            {
                                open: '16:00',
                                close: '18:00'
                            }
                        ]
                    },
                    {
                        day: 2,
                        isOpen: false,
                        opening: [
                            {
                                open: '16:00',
                                close: '18:00'
                            }
                        ]
                    },
                    {
                        day: 3,
                        isOpen: false,
                        opening: [
                            {
                                open: '16:00',
                                close: '18:00'
                            }
                        ]
                    },
                    {
                        day: 4,
                        isOpen: false,
                        opening: [
                            {
                                open: '16:00',
                                close: '18:00'
                            }
                        ]
                    },
                    {
                        day: 5,
                        isOpen: false,
                        opening: [
                            {
                                open: '16:00',
                                close: '18:00'
                            }
                        ]
                    },
                    {
                        day: 6,
                        isOpen: false,
                        opening: [
                            {
                                open: '16:00',
                                close: '18:00'
                            }
                        ]
                    },
                    {
                        day: 7,
                        isOpen: false,
                        opening: [
                            {
                                open: '16:00',
                                close: '18:00'
                            }
                        ]
                    }
                ]
            }
        }
    },
    data() {
        return {
            menu: null,
            menu2: null,
            updatedSchedule: JSON.parse(JSON.stringify(this.schedule))
        }
    },
    computed: {
        scheduleDialog: {
            get() {
                return this.value
            },
            set(val) {
                this.$emit('input', val)
            }
        },
        // computedSchedule: {
        //     get() {
        //         return this.schedule
        //     },
        //     set(val) {
        //         this.$emit('update:schedule', val)
        //     }
        // }
    },
    watch: {
        value(val) {
            if (val) {
                this.updatedSchedule = JSON.parse(JSON.stringify(this.schedule))
            }
        }
    },
    created() {
        this.menu = this.schedule.map(day => {
            return day.opening.map(() => {
                return false
            })
        })
        this.menu2 = this.schedule.map(day => {
            return day.opening.map(() => {
                return false
            })
        })
    },
    mounted() {

    },
    methods: {
        getWeekDay(day) {
            return moment().locale('es').day(day).format('dddd')
        },
        addTimeSlot(day) {
            day.opening.push({
                open: '16:00',
                close: '18:00'
            })
        },
        removeTimeSlot(day, rowIdx) {
            day.opening.splice(rowIdx, 1)
        },
        close() {
            this.scheduleDialog = false
        },
        save() {
            this.$emit('update:schedule', this.updatedSchedule)
            this.scheduleDialog = false
        }
    },
}

</script>
<style scoped>
.schedule-input {
    width: 100%;
    max-width: 100px;
}
</style>