import axios from 'axios'
// import store from '@/plugins/store'

const authApi = axios.create({
  baseURL: `${process.env.VUE_APP_API_HOST}:${process.env.VUE_APP_API_PORT}/api/v1/`,
  headers: {
    "Content-Type": "application/json",
    "Accept": "application/json",
  },
})

authApi.interceptors.request.use(function (config) {
  // store.commit('startLoading')
  return config
}, function (error) {
  return Promise.reject(error)
})

// authApi.interceptors.response.use(undefined, function () {
//   // store.commit('stopLoading')
// })


export default authApi
