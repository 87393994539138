/* eslint-disable indent */
<template>
    <v-main>
        <v-container class="py-15 px-6" style="max-width: 1250px;">
            <v-row class="d-flex justify-center">
                <v-col cols="12" md="7"  class="d-none d-md-flex flex-column justify-end ">
                    <h1 style="font-size: 54px;">Aplicación de Gestión de Reservas online para negocios</h1>
                    <!-- <h1 class="text-h2">Aplicación de Gestión de Reservas online para negocios</h1> -->
                    <h2 class="font-weight-regular py-8">Reservas online y gestión de horarios</h2>
                    <v-btn class="pa-8 align-self-start" elevation="0" color="teal accent-4" style="font-size: 18px;" dark><span class="custom-transform-class  font-weight-bold" link @click="$router.push('/signup')">Crear una cuenta</span></v-btn>
                </v-col>
                <v-col cols="11" sm="8" md="5" class="pa-0 d-flex justify-center">
                    <v-img contain :src="require('@/assets/img/portada.png')" height="100%" width="100%"></v-img>
                </v-col>
                <v-col cols="12" class="d-flex d-md-none flex-column mt-8 text-center">
                    <h1 style="font-size: 48px;">Aplicación de Gestión de Reservas online para negocios</h1>
                    <!-- <h1 class="text-h2">Aplicación de Gestión de Reservas online para negocios</h1> -->
                    <h2 class="font-weight-regular py-8">Reservas online y gestión de horarios</h2>
                    <v-btn class="pa-8 align-self-center" elevation="0" color="teal accent-4" style="font-size: 18px;" dark><span class="custom-transform-class  font-weight-bold" link @click="$router.push('/signup')">Crear una cuenta</span></v-btn>
                </v-col>
            </v-row>
        </v-container>
        <v-container class="py-15 mt-10 px-6" style="max-width: 1250px;">
            <v-row class="d-flex justify-center">
                <v-col cols="12" class="d-flex flex-column justify-center text-center">
                    <h1 style="font-size: 45px;">Gestiona tu negocio de forma fácil y económica</h1>
                    <h3 class="font-weight-regular py-2">Comparte tus horarios y disponibilidad con tus clientes, y recibe sus reservas sin ocuparte de nada</h3>
                </v-col>
                <v-row cols="12" class="d-flex justify-center">
                    <v-col cols="12" sm="6" md="4" class="pa-3 d-flex align-stretch" v-for="feature in features" :key="feature.title">
                        <v-hover
                            v-slot="{ hover }"
                        >
                            <v-card class="pa-3 mx-3" :class="{ 'on-hover': hover }">
                                <v-card-title class="pb-0">
                                    <v-icon large color="teal accent-4">{{ feature.icon }}</v-icon>
                                </v-card-title>
                                <v-card-title class="font-weight-black pb-3 teal--text text--accent-4">
                                    {{ feature.title }}
                                </v-card-title>
                                <v-card-text class="black--text font-weight-regular pb-6" style="font-size: 16px;">
                                    {{ feature.description }}
                                </v-card-text>
                            </v-card>
                        </v-hover>
                    </v-col>
                </v-row>
            </v-row>
        </v-container>
        <v-container class="py-15 mt-10 blue-grey lighten-5 px-3" fluid>
            <v-container class="mt-10" style="max-width: 1250px;">
                <v-row>
                    <v-col cols="12" class="mb-6 d-flex flex-column justify-center text-center">
                        <h1 style="font-size: 45px;">Productos adaptados a tu tipo de negocio</h1>
                        <h3 class="font-weight-regular py-2">Encuentra una solución específica pensada para tu tipo de negocio concreto</h3>
                    </v-col>
                    <v-col cols="12">
                        <v-row class="d-flex justify-center">
                            <v-col cols="12" sm="6" md="4" class="d-flex align-stretch pa-3" v-for="product in products" :key="product.title">
                                <v-hover
                                    v-slot="{ hover }"
                                >
                                    <v-card :class="{ 'on-hover-up': hover }" :to="`/sectors/${product.id}`">
                                        <v-img
                                        :src="product.img"
                                        height="200px"
                                        ></v-img>

                                        <v-card-title class="py-6 px-8 text-h6 text-break font-weight-bold">
                                            {{ product.title }}
                                        </v-card-title>
                                    </v-card>
                                </v-hover>
                            </v-col>
                        </v-row>
                    </v-col>
                </v-row>
            </v-container>
        </v-container>
    </v-main>
</template>

<script>
// import { mapActions } from 'vuex'
// import axios from 'axios'

export default {
    name: 'HomeView',
    data () {
    return {
        features: [
                {
                    title: 'Calendario',
                    description: 'Gestiona tus citas y reservas. Comparte tus horarios de apertura y disponibilidad con tus clientes. Planifica turnos y vacaciones de trabajadores.',
                    icon: 'mdi-calendar-clock',
                },
                {
                    title: 'Reservas online',
                    description: 'Recibe reservas y pagos online. Tus clientes podrán reservar y pagar online, sin necesidad de llamadas ni emails.',
                    icon: 'mdi-calendar-check-outline',
                },
                {
                    title: 'Recordatorios',
                    description: 'Envía recordatorios automáticos a tus clientes. Reduce las ausencias y aumenta la productividad de tu negocio.',
                    icon: 'mdi-bell-outline',
                },
                {
                    title: 'Gestión de clientes',
                    description: 'Identifica clientes que no se hayan presentado a citas anteriores, almacena sus productos y servicios adquiridos anteriormente y notas y comentarios privados.',
                    icon: 'mdi-account-group-outline',
                },
                {
                    title: 'Gestión de empleados',
                    description: 'Añade empleados a tu negocio y asigna servicios y horarios. Cada empleado tendrá su propio calendario y podrá gestionar sus citas y reservas.',
                    icon: 'mdi-account-tie-outline',
                },
                {
                    title: 'Gestión de productos',
                    description: 'Crea productos y servicios y asigna precios y duración. Cada servicio podrá ser asignado a uno o varios empleados.',
                    icon: 'mdi-cart-outline',
                }
            ],
            products: [
                {
                    id: 'bienestar',
                    title: 'Bienestar y belleza',
                    img: require('@/assets/img/bienestar.jpg')
                },
                {
                    id: 'sanitarios',
                    title: 'Sanitarios privados',
                    img: require('@/assets/img/salud.jpg')
                },
                {
                    id: 'deportes',
                    title: 'Deportes y ocio',
                    img: require('@/assets/img/deportes.jpg')
                },
                {
                    id: 'eventos',
                    title: 'Eventos y espectáculos',
                    img: require('@/assets/img/eventos.jpg')
                },
                {
                    id: 'educacion',
                    title: 'Educación y formación',
                    img: require('@/assets/img/educacion.jpg')
                },
                {
                    id: 'otros',
                    title: 'Otros negocios',
                    img: require('@/assets/img/otros.jpg')
                }
            ]
    }
    },
    computed: {
        
    },
    mounted () {
    },
    methods: {
       
    }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
/* .capitalize {
  text-transform: lowercase !important;
}

.capitalize::first-letter {
  text-transform: uppercase !important;
} */

.v-card {
  transition: box-shadow .3s ease-in-out, margin-top .2s ease-in-out;
  box-shadow: 4px 4px 16px 4px rgba(114, 114, 114, 0.2) !important;
  cursor: pointer;
  border: 1px solid rgba(0, 0, 0, 0) !important;
  border-radius: 10px;
}

.v-card:not(.on-hover) {
    box-shadow: 0 0 0 0 rgba(0,0,0,0) !important;
    border: 1px solid rgba(0, 0, 0, 0.12) !important;
}

.v-card:not(.on-hover-up) {
    margin-top: 5px !important;
    border-radius: 10px !important;
}
.on-hover-up {
    border-radius: 10px  !important;
}

</style>
