<template>
    <v-main app>
        <v-container class="d-flex justify-center pa-lg-6">
            <v-card width="950" flat color="transparent">
                <v-row>
                    <v-col cols="0" lg="4">
                        <v-card outlined>
                            <v-card-text>
                                <v-autocomplete
                                v-model="clientId"
                                label="Buscar"
                                :items="clients"
                                item-text="fullName"
                                item-value="id"
                                outlined
                                dense
                                append-icon="mdi-chevron-down"
                                class="rounded-lg"
                                @input="linkToClient(clientId)"
                                ></v-autocomplete>
                                <span class="ma-1 body-2 text-uppercase font-weight-bold">Descargar clientes</span>
                                <v-btn block elevation="0" dark class="mt-2 py-5 rounded-lg" :color="false ? 'teal accent-4' : 'grey lighten-2'">
                                    <v-icon left>mdi-download</v-icon>
                                    CSV
                                </v-btn>
                            </v-card-text>
                        </v-card>
                    </v-col>
                    <v-col cols="12" lg="8">
                        <router-view/>
                    </v-col>
                </v-row>
            </v-card>
        </v-container>
    </v-main>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'

export default {
    name: 'ClientsLayout',
    components: {
    },
    data() {
        return {
            clientId: null
        }
    },
    computed: {
        ...mapGetters(['clients'])
    },
    methods: {
        ...mapActions(['fetchClients']),
        linkToClient(clientId) {
            this.$router.push(`/businesses/${localStorage.getItem('businessId')}/clients/details/${clientId}`)
        }
    },
    created() {
        this.fetchClients()
    },
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped></style>
