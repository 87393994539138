import Vue from 'vue'
import Vuex from 'vuex'
import auth from '@/plugins/store/auth'
import business from '@/plugins/store/business'
import snackbar from '@/plugins/store/snackbar'
import clients from '@/plugins/store/clients'
import services from '@/plugins/store/services'
import employees from '@/plugins/store/employees'
import vacations from '@/plugins/store/vacations'
import bookingRule from '@/plugins/store/bookingRule'
import appointments from './appointments'
import notificationConfig from './notificationConfig'
import activity from './activity'

Vue.use(Vuex)

export default new Vuex.Store({
    modules: {
        auth,
        business,
        snackbar,
        clients,
        services,
        employees,
        vacations,
        bookingRule,
        appointments,
        notificationConfig,
        activity,
    },
    state: {
        loading: false,
        colors: [
            {
                text: 'Verde',
                value: 'teal',
            },
            {
                text: 'Gris',
                value: 'grey',
            },
            {
                text: 'Azul',
                value: 'blue',
            },
            {
                text: 'Rojo',
                value: 'red',
            },
            {
                text: 'Naranja',
                value: 'orange',
            },
            {
                text: 'Amarillo',
                value: 'yellow',
            },
            {
                text: 'Verde claro',
                value: 'green lighten-2',
            },
            {
                text: 'Verde oscuro',
                value: 'green darken-2',
            },
            {
                text: 'Azul claro',
                value: 'blue lighten-2',
            },
            {
                text: 'Azul oscuro',
                value: 'blue darken-2',
            },
            {
                text: 'Morado',
                value: 'purple',
            },
            {
                text: 'Rosa',
                value: 'pink',
            },
            {
                text: 'Café',
                value: 'brown',
            },
        ],
    },
    getters: {
        colors: state => state.colors,
        loading: state => state.loading,
    },
    mutations: {
        startLoading(state) {
            state.loading = true
        },
        stopLoading(state) {
            state.loading = false
        },
    },
    actions: {

    },

})
