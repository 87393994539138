<template>
        <v-footer
        dark
        padless
    >
        <v-card
        flat
        tile
        class="pb-8 blue-grey darken-3 white--text text-center"
        width="100%"
        >
            <v-card-text>
                <v-btn
                v-for="link in social_links"
                :key="link.link"
                class="mx-4 white--text"
                icon
                :href="link.link"
                target="_blank"
                >
                <v-icon size="24px">
                    {{ link.icon }}
                </v-icon>
                </v-btn>
            </v-card-text>

            <!-- <v-card-text class="white--text pt-0">
                Phasellus feugiat arcu sapien, et iaculis ipsum elementum sit amet. Mauris cursus commodo interdum. Praesent ut risus eget metus luctus accumsan id ultrices nunc. Sed at orci sed massa consectetur dignissim a sit amet dui. Duis commodo vitae velit et faucibus. Morbi vehicula lacinia malesuada. Nulla placerat augue vel ipsum ultrices, cursus iaculis dui sollicitudin. Vestibulum eu ipsum vel diam elementum tempor vel ut orci. Orci varius natoque penatibus et magnis dis parturient montes, nascetur ridiculus mus.
            </v-card-text> -->

            <!-- <v-divider></v-divider> -->

            <v-card-text class="white--text">
                <!-- {{ new Date().getFullYear() }} — <strong>BidApp</strong> -->
                <v-img max-height="20" contain class="d-flex justify-center mt-n5" :src="require('@/assets/logo-white.png')"></v-img>
            </v-card-text>
        </v-card>
    </v-footer>
</template>

<script>
export default {
  name: 'FooterComponent',
  data () {
    return {
        social_links: [
            {
                icon: 'mdi-facebook',
                link: 'https://www.facebook.com/profile.php?id=61556721304616'
            },
            // {
            //     icon: 'mdi-twitter',
            //     link: 'https://twitter.com'
            // },
            {
                icon: 'mdi-instagram',
                link: 'https://instagram.com/slotspot.es'
            },
            // {
            //     icon: 'mdi-linkedin',
            //     link: 'https://linkedin.com'
            // },
        ],
    }
  },
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

</style>
