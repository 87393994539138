import api from "@/services/api";

export default {
    namespaced: false,
    state: {
        business: {},
    },
    getters: {
        business: state => state.business,
    },
    mutations: {
        setBusiness(state, business) {
            state.business = business
        },
    },
    actions: {
        async fetchBusiness({ commit }) {
            const response = await api.get(`business`)
            if (response.status === 200) {
                commit('setBusiness', response.data.business)
            }
        },
        async updateBusiness({ commit }, business) {
            const response = await api.put(`business`, business)
            if (response.status === 200) {
                commit('setSnackbar', { show: true, text: 'Información de empresa actualizada correctamente', color: 'success' })
                return response.data.business
            } else {
                // TODO: Catch error and proceed
                commit('setSnackbar', { show: true, text: 'Error al actualizar la información', color: 'error' })
            }
        },
    },
};
