import Vue from 'vue'
import Router from 'vue-router'

// Public
import PublicLayout from '@/layouts/public/PublicLayout'
import PublicHomeView from '@/views/public/HomeView'
import PublicSectorsView from '@/views/public/SectorsView'
import PublicPricesView from '@/views/public/PricesView'
import PublicHelpView from '@/views/public/HelpView'
import PublicLoginView from '@/views/public/LoginView'
import PublicVerifyEmailView from '@/views/public/VerifyEmailView'
import PublicResetView from '@/views/public/ResetView'

// Business
import BusinessMainLayout from '@/layouts/business/MainLayout'
import BusinessSubLayout from '@/layouts/business/BusinessLayout'
import BusinessClientsLayout from '@/layouts/business/ClientsLayout'
import BusinessCalendarLayout from '@/layouts/business/CalendarLayout'
import BusinessActivityLayout from '@/layouts/business/ActivityLayout'
import BusinessMarketingLayout from '@/layouts/business/MarketingLayout'

import BusinessActivityView from '@/views/business/activity/ActivityView'
import BusinessClientsListView from '@/views/business/clients/ListView'
import BusinessClientsDetailsView from '@/views/business/clients/DetailsView'
import BusinessClientsFormView from '@/views/business/clients/FormView'
import BusinessCalendarView from '@/views/business/calendar/CalendarView'
import BusinessLinksView from '@/views/business/marketing/LinksView'
import BusinessPoliciesView from '@/views/business/marketing/PoliciesView'
import BusinessDetailsView from '@/views/business/business/DetailsView'
import BusinessBillingView from '@/views/business/business/BillingView'
import BusinessServicesView from '@/views/business/business/ServicesView'
import BusinessServicesFormView from '@/views/business/business/ServicesFormView'
import BusinessBookingRulesView from '@/views/business/business/BookingRulesView'
import BusinessEmployeesView from '@/views/business/business/EmployeesView'
import BusinessEmployeesFormView from '@/views/business/business/EmployeesFormView'
import BusinessVacationsView from '@/views/business/business/VacationsView'
import BusinessVacationsFormView from '@/views/business/business/VacationsFormView'
import BusinessNotificationsView from '@/views/business/business/NotificationsView'

// Discount
// import DiscountView from '@/views/public/DiscountView'

Vue.use(Router)

export default new Router({
    routes: [
        {
            path: '/discount/:code',
            name: 'DiscountView',
            // component: DiscountView,
            // props: true
            redirect: '/login'
        },
        {
            path: '/',
            component: PublicLayout,
            children: [
                {
                    path: '/',
                    name: 'HomeView',
                    component: PublicHomeView
                },
                {
                    path: '/sectors',
                    name: 'SectorsView',
                    component: PublicSectorsView,
                },
                {
                    path: '/sectors/:sectorId',
                    name: 'SectorsDetailsView',
                    component: PublicSectorsView,
                    props: true,
                },
                {
                    path: '/help/:section',
                    name: 'HelpViewSection',
                    component: PublicHelpView,
                    props: true,
                },
                {
                    path: '/help',
                    name: 'HelpView',
                    component: PublicHelpView,
                },
                {
                    path: '/prices',
                    name: 'PricesView',
                    component: PublicPricesView,
                },
                {
                    path: '/login',
                    name: 'LoginView',
                    component: PublicLoginView,
                },
                {
                    path: '/signup',
                    name: 'SignupView',
                    component: PublicLoginView,
                    props: () => ({ isSignUpProp: true })
                },
                {
                    path: '/verify-email/:token',
                    name: 'PublicVerifyEmailView',
                    component: PublicVerifyEmailView,
                    props: true
                },
                {
                    path: '/reset',
                    name: 'PublicResetView',
                    component: PublicResetView,
                },
            ]
        },
        {
            path: '/businesses',
            name: 'businessHome',
            component: PublicHelpView,        
        },
        {
            path: '/businesses/:businessId',
            name: 'business',
            redirect: '/businesses/:businessId/activity',
            component: BusinessMainLayout,
            props: true,
            children: [
                {
                    path: 'activity',
                    component: BusinessActivityLayout,
                    children: [
                        {
                            path: '',
                            component: BusinessActivityView
                        },
                    ]
                },
                {
                    path: 'calendar',
                    component: BusinessCalendarLayout,
                    children: [
                        {
                            path: '',
                            component: BusinessCalendarView
                        },
                    ]
                },
                {
                    path: 'clients',
                    component: BusinessClientsLayout,
                    children: [
                        {
                            path: '',
                            component: BusinessClientsListView
                        },
                        {
                            path: 'details/:clientId',
                            component: BusinessClientsDetailsView,
                            props: true
                        },
                        {
                            path: 'form/:clientId',
                            component: BusinessClientsFormView,
                            props: true
                        },
                    ]
                },
                {
                    path: 'business',
                    component: BusinessSubLayout,
                    redirect: 'business/details',
                    children: [
                        {
                            path: 'details',
                            component: BusinessDetailsView,
                        },
                        {
                            path: 'billing',
                            component: BusinessBillingView,
                        },
                        {
                            path: 'services',
                            component: BusinessServicesView,
                        },
                        {
                            path: 'services/:serviceId',
                            component: BusinessServicesFormView,
                            props: true
                        },
                        {
                            path: 'employees',
                            component: BusinessEmployeesView,
                        },
                        {
                            path: 'employees/:employeeId',
                            component: BusinessEmployeesFormView,
                            props: true
                        },
                        {
                            path: 'rules',
                            component: BusinessBookingRulesView,
                        },
                        {
                            path: 'notifications',
                            component: BusinessNotificationsView,
                        },
                        {
                            path: 'vacations',
                            component: BusinessVacationsView,
                        },
                        {
                            path: 'vacations/:vacationId',
                            component: BusinessVacationsFormView,
                            props: true
                        },
                    ]
                },
                {
                    path: 'marketing',
                    component: BusinessMarketingLayout,
                    redirect: 'marketing/links',
                    children: [
                        {
                            path: 'links',
                            component: BusinessLinksView,
                        },
                        {
                            path: 'policies',
                            component: BusinessPoliciesView,
                        },
                    ]
                },
            ]
        },
        // For 404 page
        // {
        //     path: '/:pathMatch(.*)*',
        //     component: LoginView
        // }
    ],
    mode: 'history',
})
